import Swiper, {Navigation, Pagination, Autoplay, Scrollbar, Controller} from 'swiper';

let sliders = document.querySelectorAll(".swiper-slider");

sliders.forEach((slider) => {
    let settings = {};
    let modules = {modules: []};

    settings['modules'] = [];
    if (slider.dataset.modulenav) {
        modules['modules'].push(Navigation);
    }

    if (slider.dataset.modulepagination) {
        modules['modules'].push(Pagination);
    }

    if (slider.dataset.moduleautoplay) {
        modules['modules'].push(Autoplay);
    }

    if (slider.dataset.modulescrollbar) {
        modules['modules'].push(Scrollbar);
    }

    if (slider.dataset.modulescontroller) {
        modules['modules'].push(Controller);
    }

    if (slider.dataset.settings) {
        settings = JSON.parse(slider.dataset.settings);
    }

    settings = merge(modules, settings);
    let swiper = new Swiper(slider, settings);

    if (typeof(slider.dataset.attachedslider) !== 'undefined') {
        let subCarousel = new Swiper('.' + slider.dataset.attachedslider, {
            slidesPerView: 1,
            slidesPerGroup: 1,
            autoHeight: true,
            modules: [
                Controller
            ]
        });

        subCarousel.controller.control = swiper;
        swiper.controller.control = subCarousel;
    }
});

function merge(modules, settings){
  	for (let key in settings){
    	if (settings.hasOwnProperty(key))
    	modules[key] = settings[key]
  	}
  	return modules
}